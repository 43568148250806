var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"flex flex--space-between flex--align-items-baseline"},[_c('span',{staticClass:"col-xs-12 col-md-9 mb-2 mb-md-0 p-0"},[_c('strong',[_vm._v(_vm._s(_vm.messages.tables.results)+" ")]),_vm._t("area"),_vm._t("period"),_vm._v(" / "),_vm._t("fiscalYear")],2),_c('ExportExcelButton',{attrs:{"tableId":_vm.tableId},on:{"export-table":function($event){return _vm.exportTableToExcel($event)}}})],1),_c('CCardBody',[_c('CDataTable',{ref:_vm.tableId,attrs:{"items":_vm.tableData,"fields":_vm.fields,"hover":"","outlined":"","border":"","clickableRows":"","columnFilter":false,"tableFilter":{
        label: _vm.messages.tables.filter,
        placeholder: `${_vm.messages.tables.search} ...`,
      },"cleaner":"","noItemsView":{
        noItems: _vm.messages.tables.noItems,
        noResults: _vm.messages.tables.noResults,
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }