<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
        <strong>{{ messages.tables.results }} </strong>
        <slot name="area"></slot>
        <slot name="period"></slot> / 
        <slot name="fiscalYear"></slot>
      </span>
      <ExportExcelButton
        :tableId="tableId"
        @export-table="exportTableToExcel($event)"
      />
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :items="tableData"
        :fields="fields"
        :ref="tableId"
        hover
        outlined
        border
        clickableRows
        :columnFilter="false"
        :tableFilter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import TableDataToExcel from "@/helpers/TableDataToExcel";
const ExportExcelButton = () =>
  import(
    /* webpackChunkName: "exportExcelButton" */ "@/components/molecules/ExportExcelButton"
  );

export default {
  name: "TableIncentivesNetwork",
  components: {
    ExportExcelButton,
  },
  data() {
    return {
      tableId: "incentiveNetwork",
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    fields() {
      let fieldsArray = [
        {
          key: "rowName",
          label: "",
        },
      ];
      this.productCodes.forEach((item) => {
        fieldsArray.push({
          key: item,
          label: this.messages.products.byCodeList[item].toUpperCase(),
        });
      });
      return fieldsArray;
    },
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    productCodes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    exportTableToExcel(tableId) {
      TableDataToExcel(
        this.$refs[tableId]._props.fields,
        this.$refs[tableId]._props.items,
        this.messages.networkIncentiveEstimation.title
      );
    },
  },
};
</script>
